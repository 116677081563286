<template>
  <div class="delete-account">
    <div class="public-screen user-ugoki-screen middle-box animated fadeIn">
      <b-row class="justify-content-center m-t-xl">
        <img class="app-logo-sm" alt="Logo" src="@/assets/images/ugoki_logo.png"/>
      </b-row>
      <h2 class="m-t-xl m-b-md">
        {{ $t('user.ugoki.delete_account.title') }}</h2>

      <p v-if="message">{{ message }}</p>
      <p class="text-danger" v-if="errorMessage">{{ errorMessage }}</p>
      <p v-html="$simpleFormat($t('user.ugoki.delete_account.body'))"></p>

      <div class="text-center">
        <hr>
        <template v-for="(link, index) in linkList">
          <span v-if="index > 0"> | </span>
          <router-link :to="link.to" target="_blank">{{ $t(link.name) }}</router-link>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
import {deleteAccount} from "@/services/users";
import GenericInput from "@/components/generic_input.vue";

export default {
  components: {GenericInput},
  beforeMount() {
    deleteAccount(this.$route.query.delete_account_token).then(response => {
      this.message = response.message
    }).catch(error => {
      this.errorMessage = error.response?.data?.error
      console.log(error)
    })
  },
  data() {
    return {
      message: null,
      errorMessage: null,
      linkList: [
        {
          to: {name: "user_ugoki_privacy"},
          name: "common.privacy_policy",
        },
        {
          to: {name: "user_ugoki_terms"},
          name: "common.terms_of_use"
        },
        {
          to: {name: "user_ugoki_imprint"},
          name: "common.imprint"
        }
      ]
    }
  },
  methods: {

  },
  computed: {

  }
}
</script>

<style scoped lang="scss">

</style>